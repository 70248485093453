import { http, http2 } from '@/lib/axios'

export const GET_STUDENTS = ({ queryKey }) => {
  const [_, args] = queryKey
  return http({
    method: 'GET',
    params: args,
    url: '/student/get',
  })
}

export const BULK_REMOVE_COURSE = (args) => {
  return http({
    method: 'GET',
    params: args,
    url: '/student/bulk_remove_from_a_course',
  })
}

export const SIGNUP_STUDENT = (values) => {
  return http({
    method: 'POST',
    data: values,
    url: '/student/signup',
  })
}

export const UPDATE_STUDENT = (values) => {
  return http({
    method: 'PUT',
    data: values,
    url: '/student/edit_profile',
  })
}

export const DELETE_STUDENT = (args) => {
  return http({
    method: 'DELETE',
    params: args,
    url: `/student/delete`,
  })
}

export const ASSIGN_COURSE_TO_STUDENTS = (args) => {
  return http({
    method: 'PUT',
    data: args,
    url: '/student/assign_course_to_student',
  })
}

export const REVOKE_COURSE_TO_STUDENTS = (args) => {
  return http({
    method: 'PUT',
    data: args,
    url: '/student/revoke_course_from_student',
  })
}

export const REMOVE_STUDENT_TOKEN = (args) => {
  return http({
    method: 'PUT',
    data: args,
    url: '/student/remove_fingerprint_token',
  })
}

export const SAVE_FREE_STUDENT = (values) => {
  return http2({
    method: 'POST',
    data: values,
    url: '/student/save_free_student',
  })
}

export const ASSIGN_COURSE_BY_EMAIL = (values) => {
  return http({
    method: 'POST',
    data: values,
    url: '/student/assign_course_by_email',
  })
}
export const REVOKE_COURSES_BY_EMAIL = (values) => {
  return http({
    method: 'POST',
    data: values,
    url: '/student/revoke_course_by_email',
  })
}

export const JOIN_AS_AFFILIATE = () => {
  return http2({
    method: 'POST',
    url: '/student/join_as_affiliate',
  })
}

export const PURCHASED_THE_COURSE = ({ queryKey }) => {
  const [_, args] = queryKey
  return http2({
    method: 'GET',
    params: args,
    url: '/student/purchased_the_course',
  })
}

export const ADD_AFFILIATE_EARNAING = (data) => {
  return http({
    method: 'POST',
    data: data,
    url: `/earning/admin/add_affiliate_earning`,
  })
}

export const MODIFY_AFFILIATION_CODE = (data) => {
  return http({
    method: 'PUT',
    data: data,
    url: `/student/modify_student`,
  })
}
